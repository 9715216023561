.reports-ctn {
  margin-top: 10px;
  background: var(--primary-color) !important;
  margin-left: 10px;
  margin-right: auto;
  margin-bottom: 10px;
  .header-ctn {
    .title {
      float: left;
      width: 100%;
      background-color: var(--theme2-bg);
      color: var(--secondary-color);   font-family: 'Roboto Condensed', sans-serif !important;
      font-weight: bolder;
      h4 {
        margin-left: 20px;   font-family: 'Roboto Condensed', sans-serif !important;
        font-weight: bolder;
      }
    }
  }
  .no-data-row {
    background-color: var(--grey-bg);
    width: 100%;
    border-bottom: 0px !important;
    color: var(--site-color);
    height: 40px;
    padding-top: 15px;
    font-family: var(--ion-font-family);
    text-align: center;
  }
}

  .reports-ctn {
    background: var(--primary-color) !important;
    border-radius: 4px;
    max-width: 1320px;
    margin-left: 10px;
    margin-right: auto;
    margin-bottom: 30px;
    .header-ctn {
      ion-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
      }
      .title {
        float: left;
        width: 100%;
        background-color: var(--theme2-bg);
        color: var(--secondary-color);
        h4 {
          margin-left: 20px;
        }
      }
    }

    .filters-row {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      .select-filter {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        height: 38px;
        background-color: var(--primary-color);
        border: 1px solid var(--grey-bg);
        border-radius: 4px;
        .select-filter-label {
          font-size: x-small;
          color: var(--ion-text-light-secondary);
        }
        .select-filter-control {
          margin-top: 0.1rem;
          min-width: 180px;
          max-width: 220px;
          border: 0px;
          background-color: var(--primary-color);
          color: var(--grey-bg);
          // border-radius: 4px;
        
        }
      }
      .date-filter {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        background-color: var(--primary-color);
        .date-filter-label {
          font-size: x-small;
          color: var(--ion-text-light-secondary);
        }
        .date-filter-control {
          margin-top: 0%;
          border: none;
          border: 1px solid var(--grey-bg);
          border-radius: 4px;
          .MuiInputBase-root {
            height: 38px;
            width: 180px;
            border-radius: 4px;
            color: var(--grey-bg);
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              padding: 4px 16px;
              color: #000 !important;
            }
          }
        }
        .MuiInputBase-root .MuiSvgIcon-root {
          color: var(--grey-bg) !important;
        }
        input::placeholder {
          color: #000;
        }
      }
      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }

    .content-ctn {
      margin-top: 1rem;
      background-color: var(--primary-color) !important;

      &.light-bg {
        background-color: var(--reports-bg-color);
      }

      .tbl-ctn:not(.no-hov-style) {
        .MuiTableContainer-root {
          .MuiTable-root {
            border: 1px solid (--grey-bg);
            // .MuiTableBody-root {
            //   .MuiTableRow-root:hover {
            //     background-color: rgba(255, 167, 36, 0.2) !important;
            //   }
            // }
          }
        }
      }

      .table-ctn,
      .tbl-ctn {
        .MuiTableContainer-root {
          background-color: inherit;
          .MuiTable-root {
            // .MuiTableHead-root {
            //   border: 1px solid (--grey-bg);
            // }
            .MuiTableBody-root {
              .MuiTableRow-root:nth-child(even) {
                background: var(--primary-color);
              }
              .MuiTableRow-root:hover {
                background-color: var(--primary-color);
              }
              .MuiTableBody-root {
                .MuiTableRow-body {
                  border: 2px solid var(--grey-bg);
                }
              }
              .MuiTableRow-root {
                border-bottom: 2px solid var(--grey-bg);
                color: var(--site-color) !important;
                .MuiTableCell-body,
                .MuiSvgIcon-root {
                  color: var(--site-color) !important;
                }
              }
              .MuiTableRow-root.back-odd-row {
                background: var(--back-odd-background);
              }
              .MuiTableRow-root.lay-odd-row {
                background: var(--lay-odd-background);
              }
            }
          }
        }
      }
    }

    .header-ctn,
    .content-ctn {
      .points-type-toggle {
        background-color: var(--primary-color) !important;
        display: inline-flex;
        height: 48px;
        .MuiBottomNavigationAction-root {
          color: var(--site-color) !important;
          font-weight: 500;
          font-size: 16px;
          padding: 0px !important;
          &.Mui-selected {
            color: var(--site-color) !important;
            background: var(--ion-color-success);
          }
        }
      }
    }

    .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
      color: var(--site-color) !important;
      border: 1px solid var(--grey-bg);
      padding: 3px 3px;
      span {
        color: var(--site-color) !important;
      }
      &.profit {
        color: var(--ion-color-success) !important;
      }
      &.loss {
        color: var(--ion-color-danger) !important;
      }
    }
  }


@media (min-width: 721px) and (max-width: 1199px) {
  .reports-ctn .header-ctn {
    .page-title,
    .img-page-title-row {
      padding-left: 20px !important;
      margin-top: 0.3rem;
      display: inline-flex;
      align-items: center;   font-family: 'Roboto Condensed', sans-serif !important;
      font-weight: bolder;
      .title-image {
        height: 21px;
        width: 21px;
      }
      .title {
        margin-left: 0.3rem;
        font-size: 20px;   font-family: 'Roboto Condensed', sans-serif !important;
        font-weight: bolder;
      }
    }
  }
  .header-ctn {
    ion-row,
    .header-ctn-row {
      flex-direction: column;   font-family: 'Roboto Condensed', sans-serif !important;
      font-weight: bolder;
    }
  }
  .filters-row {
    margin-top: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .select-filter {
      margin: 0.3rem;
      flex: 0 0 21%;
      
      .select-filter-label {
        font-size: 10px;
        color: var(--site-color) !important;
      }
      .select-filter-control {
        margin-top: 0.1rem;
        width: 100%;
        font-size: 10px;
        background-color: var(--primary-color);
        border: 1px solid var(--grey-bg);
      }
    }
    .date-filter {
      margin: 0.3rem;
      flex: 0 0 21%;
      color: var(--site-color) !important;
      .date-filter-label {
        font-size: 10px;
        color: var(--site-color) !important;
      }
      .date-filter-control {
        margin-top: 0%;
        border-bottom: 1px solid var(--grey-bg);
        .MuiInputBase-root {
          height: 30px;
          width: 100%;
          color: var(--site-color);
          .MuiInputBase-input,
          .MuiOutlinedInput-input {
            font-size: 10px;
            padding: 4px 16px;
            color: #000 !important;
          }
        }
      }
      .date-filter-control .MuiInputBase-root .MuiInputBase-input,
      .date-filter-control .MuiInputBase-root .MuiOutlinedInput-input {
        color: #000 !important;
      }
    }
    .readonly {
      background: rgba(207, 207, 207, 0.5);
    }
  }
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }
  .reports-ctn {
    margin: 0px 0px !important;
    border-radius: 0px;
    .header-ctn {
      .title {
        float: left;
        width: 100%;
        background-color: var(--theme2-bg);
        color: var(--secondary-color);
        h4 {
          margin-left: 20px;
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }
    .content-ctn {
      padding: 0 8px;
      .points-type-toggle {
        border: 1px solid #636c89;
        height: 32px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }
    }
    .header-ctn {
      ion-row,
      .header-ctn-row {
        flex-direction: column;
      }
    }
    .filters-row {
      display: flex;
      flex-wrap: wrap;
      .select-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        .select-filter-label {
          font-size: 10px;
          color: var(--site-color);
        }
        .select-filter-control {
          margin-top: 0.1rem;
          width: 100%;
          font-size: 10px;
        }
      }
      .date-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        .date-filter-label {
          font-size: 10px;
          color: var(--site-color);
        }
        .date-filter-control {
          margin-top: 0%;
          border-bottom: 1px solid var(--filter-controls-border);
          .MuiInputBase-root {
            height: 30px;
            width: 100%;
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 10px;
              padding: 4px 16px;
            }
          }
        }
      }
      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .page-title {
        font-size: 26px;
        line-height: 22px;
        padding-left: 10px !important;
      }

      .img-page-title-row {
        margin-top: 0.3rem;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        display: flex;
        align-items: baseline;
        .title-image {
          height: 21px;
          width: 21px;
        }
        .title {
          font-size: 26px;
          line-height: 22px;
          margin-left: 0.3rem;
        }
      }

      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }
        .summary-expand-toggle {
          text-align: center;
          .summary-expand-toggle-btn {
            color: var(--ion-text-light);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;
            ion-icon {
              color: var(--ion-text-light);
              height: 18px;
            }
          }
        }
      }
    }

    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
        }
      }
    }

    .txt-info {
      font-weight: bold;
      font-size: 13px;
    }

    .mob-fs-13 {
      font-weight: bold !important;
      font-size: 13px;
      text-transform: capitalize;
    }

    .mob-fs-14 {
      font-weight: bold !important;
      font-size: 14px;
      text-transform: capitalize;
    }

    .mob-fs-16 {
      font-weight: bold !important;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .text-ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--site-color) !important;
  }
}

.icon-text-section {
  display: inline-flex;
  .icon-div {
    border-radius: 25%;
    font-size: 8px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
    padding: 10px;
    height: fit-content;
    .img {
      width: 20px;
      height: 20px;
    }
  }
}

.date-filter-control {
  .MuiInputBase-root {
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      color: var(--site-color) !important;
    }
  }
}
