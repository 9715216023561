.main-header-ctn {
  width: 100vw;
  height: 80px;
  background: var(--theme1-bg) !important;
  margin-top: 0% !important;
  border-radius: 0px;
  margin-right: auto;
  font-family: 'Roboto Condensed', sans-serif !important;
  .branding-ctn {
    .fa-home {
      display: none;
    }
    display: flex;
    cursor: pointer;
    .site-title {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .site-logo {
        width: 100px;
        height: 74px;
        padding-top: 10px;
      }
      .logo-name {
        text-align: center;
        margin-top: -7px;
        font-size: 11px;
        opacity: 0.8;
        font-family: -webkit-body;
      }
    }
    .search-bar {
      margin-left: 3%;
      .search-and-remove {
        display: flex;
        ::placeholder {
          font-size: 12px !important;
          // color: var(--ion-text-white) !important;
          color: aqua;
        }
        .search-input-bar {
          background-color: rgb(42, 41, 41) !important;
          width: 280px;
          height: 33px;
          border-radius: 8px;
          border: 1px solid var(--filter-border);
          color: var(--ion-text-white) !important;
          font-family: var(--font-family);
          @media screen and (max-width:395px){
            width: 240px;
          }
        }
        input:focus {
          outline: none;
        }
        .removeQuery {
          position: relative;
          right: 20px;
          height: 16px;
          width: 0px;
          font-size: 18px;
          font-weight: normal;
        }
      }

      .eventList {
        width: 215px;
        max-height: 200px;
        background-color: var(--ion-text-white) !important;
        color: black !important;
        font-size: 12px;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
        top: 50px;
        text-overflow: ellipsis !important;
        border-radius: 3px;

        .individEvent {
          padding: 5px;
          height: fit-content;
          width: 100%;
        }

        .individEvent:hover {
          height: fit-content;
          width: 100%;
          background-color: rgb(199, 199, 199) !important;
          text-decoration: underline;
        }
          @media only screen and (max-width:720px){
            position: absolute;top:36px;
          }

      }
    }
  }

  .header-row {
    display: flex;
    // justify-content: space-between;
    margin-top: 4px;
    font-family: 'Roboto Condensed', sans-serif !important;
  }

  .h-btns {
    font-size: 18px;
    font-weight: 600;
    color: var(--ion-color-primary-contrast);
    background-color: var(--ion-color-primary);
    letter-spacing: 0.02em;
  }

  .header-actions {
    display: flex;
    align-items: center;
    .header-right-actions {
      display: flex;
      align-items: center;
      font-family: 'Mulish', sans-serif;
      font-size: 14px;
      cursor: pointer;
      .web-hide {
        display: none;
      }
      .profile-section {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Roboto Condensed', sans-serif !important;
      }
      .h-list-item {
        min-width: 120px;
        .fa-android {
          margin-left: 3px;
        }
      }
      .h-list-item-search {
        min-width: 40px;
      }
    }
  }
}

.user-popover-list {
  margin: 0px !important;
  padding: 0px !important;
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: bolder;
  .nav-link {
    text-decoration: none;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bolder;
  }
  .MuiTypography-body1 {
    font-size: 13px !important;
    color: var(--site-color) !important;
    margin: 0px !important;
    padding: 0px !important;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bolder;
  }
  .MuiListItem-root {
    padding-top: 2px;
    padding-bottom: 1px;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bolder;
  }
  .MuiTypography-displayBlock {
    padding: 0px;
    margin: 0px;
  }
  .MuiListItemText-root {
    color: var(--site-color) !important;
    font-size: 13px;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bolder;
  }

  // .MuiListItemText-root:hover {
  //   border-bottom: 1px solid var(--site-color);
  // }
}

@media screen and (max-width: 720px) {
  .main-header-ctn {
    height: 95px;
    padding: 0px;
    .branding-ctn {
      .fa-home {
        display: flex;
        margin-top: 15px;
        padding: 3px;
        font-size: 22px;
      }
    }
    .site-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin-top: 0px !important;
      .site-logo {
        width: 65px !important;
        height: auto !important;
        margin-left: 10px;
        img{width: 70px;}
      }
    }

    .h-btns {
      padding: 2px 20px;
    }

    .live-clock {
      display: none;
    }

    .header-actions {
      width: 68%;
      height: 100%;
      justify-content: flex-end;
      font-family: 'Roboto Condensed', sans-serif !important;
      line-height: 20px;
      .header-right-actions {
        display: flex;
        flex-direction: row;
        .mobile-hide {
          display: none;
        }
        .web-hide {
          display: flex;
        }
        .searc-web-hide {
          display: none;
        }
        .profile-section {
          display: flex;
          flex-direction: column;
          justify-items: end;
          font-family: 'Roboto Condensed', sans-serif !important;
          .profile-section-username {
            display: flex;
            flex-direction: row;
            justify-items: end;
            font-family: 'Roboto Condensed', sans-serif !important;
          }
        }
        .profile-section-mobile-view {
          display: flex;
          flex-direction: column;
          justify-items: end;
          font-weight: normal;
          font-size: 16px;
          margin-right: 6px;

          .bank-icon {
            font-size: 18px;
          }

          .bal-view-mobile {
            // font-size: 12px;
            text-align: end;
            font-weight: normal;
            margin-top: -15px;
          }
          .mobile-view-exp {
            border-bottom: 1px solid var(--primary-color);
            font-family: 'Roboto Condensed', sans-serif !important;
          }
          .mobile-view-username {
            margin-left: 2px;
            margin-right: 2px;
            border-bottom: 1px solid var(--primary-color);
            font-family: 'Roboto Condensed', sans-serif !important;
          }
        }
        .h-list-item {
          min-width: 65px;
          font-size: 10px;
          font-weight: 600;
          margin-top: auto;
          margin-bottom: auto;
          font-family: 'Roboto Condensed', sans-serif !important;
        }
      }
    }
  }
}
